import React, {useEffect, useState} from "react";
import {fetchSex} from "../api";
import {Select} from "../components/UI/FormItems";

const SexContainer = (props) => {
  const [sexData, setSexData] = useState([]);

  useEffect(() => {
    async function fetchStateSexs() {
      fetchSex().then((data) => setSexData(data));
    }

    fetchStateSexs().then(() => {});
  }, []);

  return (
    <Select {...props}>
      <option value={0}>Не выбран</option>
      {sexData.map((sex) => {
        return (
          <option key={sex.id + "_sex"} value={sex.id}>
            {sex.value}
          </option>
        );
      })}
    </Select>
  );
};

export default SexContainer;
