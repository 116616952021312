import * as Yup from "yup";
import {useHistory} from 'react-router-dom'
import {Field, Form, Formik} from "formik";
import {Select, TextInput} from "../components/UI/FormItems";
import {rejectApp} from "../api";
import React, {useState} from "react";
import {fetchRejectionReasons} from "../api";

const Rejection = ({applicationId, onCallBack}) => {

    const history = useHistory();

    const [reasons, setReasons] = useState([]);
    const [processing, setProcessing] = useState(false)

    const handleSelectBaseReason = async (rejectedByClient) => {
        fetchRejectionReasons(rejectedByClient).then((data) => setReasons(data))
    }

    const handlerCancelRejection = async () => {
        onCallBack();
    };

    const clientSchema = Yup.object({
        rejectionReason: Yup.number().moreThan(0).required()
    })

    return (
        <div className="rejection">
            <fieldset className="rejection-content">
                <Formik
                    initialValues={{
                        rejectionReason: null,
                        baseReason: null,
                        comment: null
                    }}
                    validationSchema={clientSchema}
                    validateOnMount
                    initialTouched={{ baseReason: true, rejectionReason: true }}
                    onSubmit={async (values) => {
                        setProcessing(true)
                        await rejectApp(applicationId, values.rejectionReason, values.comment);
                        history.push("/");
                    }}
                >
                    {({values}) => <Form>
                        <div role="group">
                            <label>
                                <Field
                                    type="radio"
                                    name="baseReason"
                                    value="mkk"
                                    onClick={() => {
                                        values.rejectionReason = 0;
                                        handleSelectBaseReason(false).then(() => {});
                                    }}
                                />
                                Отказ МКК
                            </label>
                            <div/>
                            <label>
                                <Field
                                    type="radio"
                                    name="baseReason"
                                    value="client"
                                    onClick={() => {
                                        values.rejectionReason = 0;
                                        handleSelectBaseReason(true).then(() => {});
                                    }}
                                    />
                                Отказ клиента
                            </label>
                        </div>
                        <div>
                            <Select {...reasons}
                                    label="Причина отказа:"
                                    name="rejectionReason"
                            >
                                <option value={0}>Не выбрана</option>
                                {reasons.map((reason) => {
                                    return (
                                        <option key={reason.id + '_reason'} value={reason.id}>
                                            {reason.name}
                                        </option>
                                    );
                                })}
                            </Select>
                        </div>
                        <div className="rejection-comment">
                            <TextInput
                                name="comment"
                                placeholder="Комментарий менеджера"
                            />
                        </div>
                        <div>
                            <button
                                className={!processing ? "btn" : "not-active-btn"}
                                type="submit"
                            >
                                Записать отказ и закрыть заявку
                            </button>
                            <button
                                style={{ marginLeft: "10px" }}
                                className={!processing ? "btn" : "not-active-btn"}
                                type="text"
                                onClick={handlerCancelRejection}
                            >
                                Отмена отказа
                            </button>
                        </div>
                    </Form>}
                </Formik>
            </fieldset>
        </div>
    );
};

export default Rejection;