import {Text} from "./UI/FormItems";

const LoanDetails = ({application}) => {

  return (
      <fieldset>
        <legend align = "left" > Кредитная заявка </legend>
          <div className = "grid-container" >
            <Text label = {
              "Номер заявки"
            }
            value = {
              application.id
            }
            />
            <Text label = {
              "Максимально одобренная сумма"
            }
            value = {
              application.limit
            }
            />
            <Text label = {
              "Дата одобрения"
            }
            value = {
              application.apprDate
            }
            />
            <Text label = {
              "Минимально одобренная сумма"
            }
            value = {
              15000
            }
            />
            <Text label = {
              "Окончание действия"
            }
            value = {
              application.endDate
            }
            />
            <Text label = {
              "Максимальная ставка"
            }
            value = {
              application.maxIr
            }
            />
            <Text label = {
              "Максимальный срок"
            }
            value = {
              application.term
            }
            />
          </div>
    </fieldset>
  );
};
export default LoanDetails;