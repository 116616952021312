import React, {useEffect, useState} from "react";
import {fetchMaritalStatuses} from "../api"
import {Select} from "../components/UI/FormItems";

const MaritalStatusContainer = (props) => {
  const [status, setStatus] = useState([]);

  useEffect(() => {
    async function fetchStatuses() {
      fetchMaritalStatuses().then((data) => setStatus(data));
    }

    fetchStatuses().then(() => {});
  }, []);

  return (
    <Select {...props}>
      <option value={0}>Не выбран</option>
      {status.map((marital) => {
        return (
          <option key={marital.id + '_marital'} value={marital.id}>
            {marital.description}
          </option>
        );
      })}
    </Select>
  );
};

export default MaritalStatusContainer;
