import {baseURL} from "./Environment.js";
import {clientCC} from "../services/HttpService";

//Получение данных о регионах
export const fetchRegions = () => {
    return clientCC.get(`${baseURL}/references/regions`).then((res) => {
        return res.data.content;
    });
};

//Получение данных о странах
export const fetchCountries = () => {
    return clientCC.get(`${baseURL}/references/countries`).then((res) => {
        return res.data.content;
    });
};

//Получение данных о половой принадлежности
export const fetchSex = () => {
    return clientCC.get(`${baseURL}/references/sex`).then((res) => {
        return res.data.content;
    });
};

//Получение данных о семейном положении
export const fetchMaritalStatuses = () => {
    return clientCC.get(`${baseURL}/references/marital-statuses`).then((res) => {
        return res.data.content;
    });
};

//Получение причин отказа
export const fetchRejectionReasons  = (rejectedByClient) => {
    return clientCC.get(`${baseURL}/references/rejection-reasons/${rejectedByClient}`).then((res) => {
        return res.data.content;
    });
};

//Получение списка территориальных дирекций
export const fetchTerritorialDirections = () => {
    return clientCC.get(`${baseURL}/references/territorial-directions`).then((res) => {
        return res.data.content;
    });
};

//Получение списка точек продаж по id территориальной дирекции
export const fetchPointsOfSale  = (tdId) => {
    return clientCC.get(`${baseURL}/references/points-of-sale?territorialDirectionId=${tdId}`)
        .then((res) => {return res.data.content});
};

//Получение страховых продуктов
export const fetchInsuranceProductPrice = async (amount) => {
    try {
        const {data} = await clientCC.get(`${baseURL}/references/insurance-product?amount=${amount}`);
        return data;
    } catch (e) {
        return false;
    }
};