import React from "react";
import {Select} from "../components/UI/FormItems";

const RegionContainer = (props) => {

  return (
    <Select
      name={props.name}
      label={props.label}
      style={props.style}
      disabled={props.disabled}
    >
      {props.canNotSelect && <option value={0}>не выбрано</option>}
      {props.regions.map((region) => {
        return (
          <option
            key={region.id + "_region"}
            value={region.id}
          >
            {props.keyname ? region[props.keyname] : region.rtmName}
          </option>
        );
      })}
    </Select>
  );
};

export default RegionContainer;
