import {Form, Formik} from "formik";
import React, {useEffect, useState} from "react";
import {fetchInsuranceProductPrice} from "../api";

const Insurance = ({term, amount, onCallBack}) => {

    const [insuranceProducts, setInsuranceProducts] = useState([]);

    const handlerCancelInforming = async () => {
        onCallBack();
    };

    useEffect(() => {
        async function initialize() {
            const resp = await fetchInsuranceProductPrice(amount);
            if (resp) {
                setInsuranceProducts(resp);
            } else {
                alert("Ошибка при получении страховых продуктов");
            }
        }

        initialize().then(() => {});
    }, []);

    return (
        <div className="rejection">
            <fieldset className="rejection-content">
                <Formik initialValues={{}}>
                    {() => <Form>
                        <div>Предлагаемые страховые продукты для займа на срок {term} месяцев, на сумму {amount}р.</div>
                        <div style={{ marginTop: "20px"}}/>
                        {insuranceProducts.map((product) => {
                            return (
                                <div style={{ marginTop: "20px"}}>
                                    Страховой продукт "{product.name}" на срок {product.term} месяцев,
                                    сумма страховки - {product.price}р. Клиенту на руки - {amount-product.price}р.
                                </div>
                            )
                        })}
                        <div style={{ marginTop: "20px"}}/>
                        <div>
                            <button
                                style={{ marginLeft: "10px" }}
                                className={"btn"}
                                type="text"
                                onClick={handlerCancelInforming}
                            >
                                Закрыть окно
                            </button>
                        </div>
                    </Form>}
                </Formik>
            </fieldset>
        </div>
    );
};

export default Insurance;