const Payments = ({items = []}) => (
    <fieldset>
        <legend align="left">График платежей</legend>
        <table>
            <thead>
            <tr>
                <th>итоге к уплате</th>
                <th>платеж в погашение основного долга</th>
                <th>остаток задолженности по займу</th>
                <th>срок платежа, не позднее указанной даты</th>
                <th>платежи в погашение процентов</th>
            </tr>
            </thead>
            <tbody>
            {items.map((payment) => {
                return (
                  <tr key={payment.id + "_payment"}>
                      <td>{payment.paymentSum}</td>
                      <td>{payment.paymentMainSum}</td>
                      <td>{payment.remainedMainSum}</td>
                      <td>{payment.paymentDate}</td>
                      <td>{payment.interestAmount}</td>
                  </tr>
                )
            })}

            </tbody>
        </table>
    </fieldset>
)

export default Payments
