import React, {useEffect, useMemo, useState} from "react";
import {useFilters, useGlobalFilter, usePagination, useSortBy, useTable,} from "react-table";
import {matchSorter} from "match-sorter";
import {useHistory} from "react-router-dom";
import {Form, Formik} from "formik";
import {TextInput} from "./UI/FormItems";
import {checkAdminRole} from "../api";

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

fuzzyTextFilterFn.autoRemove = (val) => !val;

const AllAppTable = ({ apps, pageCount: controlledPageCount, fetchData }) => {
  const history = useHistory();

  const [hasRoleAdmin, setHasRoleAdmin] = useState(false);

    useEffect(() => {

        async function initialize() {
            await fetchRole();
        }

        async function fetchRole() {
            const resp = await checkAdminRole();
            setHasRoleAdmin(resp);
        }
        initialize().then(() => {});
    }, []);

  const filterTypes = useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterFn,
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const renderStatus = (status) => {
    return status.value;
  }

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "ID отделения",
        accessor: "branchId",
      },
      {
        Header: "mkkAppId",
        accessor: "mkkAppId",
      },
      {
        Header: "Статус",
        accessor: "status",
        Cell: renderStatus,
      },
      {
        Header: "Дата заведения",
        accessor: "apprDate",
      },
      {
        Header: "Дата окончания",
        accessor: "endDate",
      },
      {
        Header: "ФИО",
        accessor: "name",
      },
      {
        Header: "Дата рождения",
        accessor: "birthdate",
      },
      {
        Header: "Паспорт",
        accessor: "passport",
      },
      {
        Header: "Лимит",
        accessor: "limit",
      }
    ],
    []
  );

  const data = useMemo(() => apps, [apps]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
    visibleColumns,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 15 },
      manualPagination: true,
      pageCount: controlledPageCount,
      filterTypes,
      autoResetPage: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    fetchData({ pageIndex, pageSize });
  }, [fetchData, pageIndex, pageSize]);

  const handleRowClick = async (row) => {
    const appId = row.values.mkkAppId

    if(!appId) return;

    history.push("/application/?mkk_id=" + appId);
  };

  const handleSubmit = (values) => {
    fetchData({pageIndex, pageSize, filter: values});
  }

  return (
    <>
      <table {...getTableProps()}>
        <thead>
        <tr>
          <th
            colSpan={visibleColumns.length}
            style={{
              textAlign: "left",
            }}
          >
            <Formik
              initialValues={{
                  id: null,
                  lastName: null,
                  firstName: null,
                  middleName: null,
                  birthDate: null,
                  passportSeries: null,
                  passportNumber: null
              }}
              onSubmit={handleSubmit}
            >
              {() => (
                <Form>
                    <div>
                        <TextInput
                            label="ID Заявки"
                            name="id"
                            placeholder="12345"
                            style = {{width: 60}}
                        />
                        <TextInput
                            label="Фамилия"
                            name="lastName"
                            placeholder="Иванов"
                        />
                        <TextInput
                            label="Имя"
                            name="firstName"
                            placeholder="Иван"
                        />
                        <TextInput
                            label="Отчество"
                            name="middleName"
                            placeholder="Иванович"
                        />
                    </div>
                    <TextInput
                        type="date"
                        label="Дата рождения"
                        name="birthDate"
                    />
                    <TextInput
                        label="Серия паспорта"
                        name="passportSeries"
                        placeholder="1234"
                    />
                    <TextInput
                        label="Номер паспорта"
                        name="passportNumber"
                        placeholder="123456"
                    />
                  <button className="btn" name="calculate" type="submit" style={{ marginLeft: "12px" }}>
                    Найти
                  </button>
                    {hasRoleAdmin &&
                    <button
                        className="btn"
                        type="text"
                        onClick={() => history.push("/admin")
                        }
                        style={{ marginLeft: "12px" }}
                    >
                        Кабинет администратора
                    </button>}
                </Form>
              )}
            </Formik>
          </th>
        </tr>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {column.render("Header")}
                    <div>
                      <span style={{ marginLeft: "6px" }}>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </span>
                    </div>
                  </div>
                </th>
              ))}
            </tr>
          ))}

        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} onClick={() => handleRowClick(row)}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="pagination">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {"<<"}
        </button>{" "}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {"<"}
        </button>{" "}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {">"}
        </button>{" "}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {">>"}
        </button>{" "}
        <span>
          Страница{" "}
          <strong>
            {pageIndex + 1} из {pageOptions.length}
          </strong>{" "}
        </span>
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[5,15, 25, 50, 100].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Показать {pageSize}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};

export default AllAppTable;

