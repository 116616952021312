import Header from "./UI/Header";

export default function EmptyContent({ header, message }) {
    return (
        <>
            <Header title={header} />
            <table>
                <thead>
                <tr>
                    <th>
                        {message}
                    </th>
                </tr>
                </thead>
            </table>
        </>
    );
}
