import {baseURL, forceDownload} from "./Environment.js";
import {clientCC} from "../services/HttpService";

//Получение всех заявок
export const fetchAllApps = async (pageSize, pageIndex, filter = null) => {
  try {
    let params = new URLSearchParams();
    if (filter != null) {
      if (filter.id != null) params.append("id", filter.id);
      if (filter.lastName != null) params.append("lastName", filter.lastName);
      if (filter.firstName != null) params.append("firstName", filter.firstName);
      if (filter.middleName != null) params.append("middleName", filter.middleName);
      if (filter.birthDate != null) params.append("birthDate", filter.birthDate);
      if (filter.passportSeries != null) params.append("passportSeries", filter.passportSeries);
      if (filter.passportNumber != null) params.append("passportNumber", filter.passportNumber);
    }
    const {data} = await clientCC.get(`${baseURL}/application?size=${pageSize}&page=${pageIndex}`, {params: params});
    return data;
  } catch (e) {
    alert('Не удалось получить список заявок.');
  }
};

//Получение доступности роли "ADMIN"
export const checkAdminRole = async () => {
  try {
    const { data } =  await clientCC.get(`${baseURL}/application/check-admin-role`);
    return data.content;
  } catch (e) {
    alert('Не удалось получить роль пользователя');
  }
};

//Получение данных о заявке
export const fetchApp = async (id) => {
  try {
    const { data } = await clientCC.get(`/application/${id}/`);
    return data;
  } catch (e) {
    return e.response.data
  }
};

//Валидация данных клиента
export const validateClientData = async (values, applicationId) => {
  try {
    const {data} = await clientCC.post(`${baseURL}/application/${applicationId}/validate-client-data`, {
      firstName: values.client.firstName,
      lastName: values.client.lastName,
      middleName: values.client.middleName,
      birthDate: values.client.birthDate,
      passportSeries: values.client.passportSeries,
      passportNumber: values.client.passportNumber,
      passportIssueDate: values.client.passportIssueDate
      });
    return data;
  } catch (e) {
    return false;
  }
};

//Фиксация факта открытия заявки менеджером
export const applicationOpened = async (applicationId) => {
  try {
    const response = await clientCC.post(`${baseURL}/application/${applicationId}/application-opening`, {});
    return response.status === 200;
  } catch (e) {
    return false;
  }
};

//Проверка актуальности предложения по заявке в ЕБПП по id заявки
export const checkAppActual = async (applicationId) => {
  try {
    const {data} = await clientCC.post(`/application/${applicationId}/check-offer-actual`);
    return data;
  } catch (e) {
    return e.response.data;
  }
};

//Скачивание согласия АСП
export const fetchAspAgreement = async (id) => {
  try {
    const response = await clientCC.post(`${baseURL}/files/asp-agreement?applicationId=${id}`, {}, {responseType: 'blob'});
    forceDownload([response.data], 'asp_agreement.pdf');
  } catch (e) {
    alert("Ошибка получения согласия АСП!");
  }
};

//Загрузка документов клиента
export const uploadPhotosAsp = async (id,files) => {
  try {
    return await clientCC.post(`${baseURL}/files/photos-asp?applicationId=${id}`, files, { headers: { "Content-Type": "multipart/form-data" }});
  } catch (e) {
    if (e.response.status === 406) {
      alert(e.response.data.error);
    } else {
      alert("Ошибка при загрузке документов! Обратитесь к Администратору. Код ошибки " + e.response.status);
    }
    return false;
  }
};

//Изменение данных по заявке
export const patchApplication = async (id, application) => {
  try {
    return await clientCC.put(`${baseURL}/application/${id}`, application);
  } catch (e) {
    if (e.response.data?.error) {
      alert("ОШИБКА! " + e.response.data.error);
    } else {
      alert("Ошибка при обновлении данных заявки!");
    }
    return false;
  }
};

//Скачивание пакета соглашений
export const fetchAgreements = async (id) => {
  try {
    const response = await clientCC.post(`${baseURL}/files/agreements?applicationId=${id}`, {}, {responseType: 'blob'});
    forceDownload([response.data], 'agreements.zip');
  } catch (e) {
    alert("Ошибка получения пакета соглашений!");
  }
};

//Запрос в ОКБ
export const okbRequest = async (applicationId) => {
  try {
    const response = await clientCC.post(`${baseURL}/application/${applicationId}/okb-request`);
    return response.status === 200;
  } catch (e) {
    return false;
  }
};

//Запрос на проверку ограничения максимальной суммы выдачи
export const maxIssueSumCheck = async (applicationId) => {
  try {
    return await clientCC.post(`${baseURL}/application/${applicationId}/current-debt-check`)
        .then(resp => resp.data);
  } catch (e) {
    return false;
  }
};

//Проверка создания сделки
export const isCreditContractCreated = async (id) => {
  try {
    const {data} = await clientCC.get(`${baseURL}/application/${id}/check-credit-contract`);
    return data;
  } catch (e) {
    return false;
  }
};

//Проверка ПСК
export const checkDealFullCost = async (id) => {
  try {
    return clientCC.post(`${baseURL}/application/${id}/check-full-cost`)
        .then(resp => resp.data);
  } catch (e) {
    return false;
  }
};

//Проверка превышения максимального значения ПДН (Проверка МПЛ)
export const checkMplValue = async (id) => {
  try {
    return clientCC.post(`${baseURL}/application/${id}/check-pdn-value`)
        .then(resp => resp.data);
  } catch (e) {
    alert("Произошла ошибка при расчете ПДН! Обратитесь к Администратору.");
    return false;
  }
};

//Получение данных о сделке
export const fetchCreditContract = async (id) => {
  try {
    const {data} = await clientCC.get(`${baseURL}/credit-contract?applicationId=${id}`);
    return data.content;
  } catch (e) {
    return false;
  }
};

//Прересчет графика платежей по новой сумме
export const updateLoanAmount = async (id, reqSum) => {
  try {
    const {data} = await clientCC.post(`${baseURL}/credit-contract/${id}/updateLoanAmount`, {reqSum: reqSum});
    return data.content;
  } catch (e) {
    alert("Ошибка при перерасчете графика платежей!");
    return false;
  }
};

//Запрос на открытие счета
export const openAccountRequest = async (id) => {
  try {
    const response = await clientCC.post(`${baseURL}/application/${id}/check-open-account-request-sent`);
    return response.status === 200;
  } catch (e) {
    return false;
  }
};

//Запись в заявку точки продаж
export const updateApplicationPointOfSale = async(applicationId, pointOfSaleId) => {
  try {
    await clientCC.post(`${baseURL}/application/${applicationId}/point-of-sale?pointOfSaleId=${pointOfSaleId}`);
    return true;
  } catch (e) {
    return false;
  }
};

//Проверка пройденной проверки СКБ
export const fetchSkbVerification = async (id) => {
  try {
    const response = await clientCC.get(`${baseURL}/application/${id}/check-skb`);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
};

//Скачивание пакета документов сделки
export const fetchDealDocuments = async (id) => {
  try {
    const response = await clientCC.post(`${baseURL}/files/deal?applicationId=${id}`, {}, {responseType: 'blob'});
    forceDownload([response.data], 'deal_documents.zip');
    return response.status === 200;
  } catch (e) {
    if (e.response.status === 406) {
      return JSON.parse(await e.response.data.text());
    } else if (e.response.status === 404) {
      alert(e.response.data.text());
      return false;
    } else {
      return false;
    }
  }
};

//Загрузка заявления-анкеты
export const uploadAccountOpeningApplication = async (id,files) => {
  try {
    return await clientCC.post(`${baseURL}/files/account-opening-application?applicationId=${id}`, files, { headers: { "Content-Type": "multipart/form-data" }});
  } catch (e) {
    if (e.response.status === 406) {
      alert(e.response.data.error);
    } else {
      alert("Ошибка при загрузке заявления-анкеты! Обратитесь к Администратору.");
    }
    return false;
  }
};

//Отправка СМС-кода подтверждения
export const sendSmsCode = async(id, smsType) => {
  try {
    const { data } = await clientCC.post(`${baseURL}/sms`, { applicationId: id, smsType: smsType });
    return data.content;
  } catch (e) {
    return false;
  }
};

//Проверка СМС кода и скачивание документов
export const checkSmsCode = (id, typeId, code) => {
  if(typeId === "AGREEMENT") {
    return clientCC.post(`${baseURL}/files/sign-agreements`, { applicationId: id, approvalCode: code }, {responseType: 'blob'})
      .then((response) => {
        forceDownload([response.data], 'agreements_sign.zip');
        return response.status === 200;
      }).catch(() => {
        return false;
      });
  } else if(typeId === "CONTRACT") {
    return clientCC.post(`${baseURL}/files/sign-deal`, { applicationId: id, approvalCode: code }, {responseType: 'blob'})
      .then((response) => {
        forceDownload([response.data], 'deal_sign.zip');
        return response.status === 200;
      }).catch(() => {
        return false;
      });
  }
  alert("Неопознанный тип подтверждения смс!");
  return false;
};

//Обработка отказа по заявке
export const rejectApp = async(id, reasonId, comment) => {
  try {
    const { data } = await clientCC.post(`${baseURL}/application/${id}/reject`,
        {
          reasonId: reasonId,
          comment: comment
        });
    return data;
  } catch (e) {
    alert('Ошибка при обработке отказа по заявке!');
    return false;
  }
};