import React, {useEffect, useState} from "react";
import {fetchApp} from '../api';
import EmptyContent from "../components/EmptyContent";
import {useQuery} from "../components/helpers/useQuery";
import "./styles.css";
import ClientDataVerification from "./ClientDataVerification";
import FillingApplication from "./FillingApplication";
import ApplicationAgreements from "./ApplicationAgreements";
import PreCalculation from "./PreCalculation";
import LoanIssue from "./LoanIssue";
import {usfStage} from "../api/Environment";
import PhotosASP from "./PhotosASP";

export default function ApplicationProcessing() {

  const [application, setApplication] = useState(undefined);
  const [creditContract, setCreditContract] = useState(undefined);
  const [stage, setStage] = useState(usfStage);
  const [callBackMessage, setCallBackMessage] = useState("Ожидайте загрузки информации...");
  const [finalStage, setFinalStage] = useState(false)

  const mkkAppId = useQuery().get("mkk_id");

  useEffect(() => {
    async function initialize(mkkAppId) {
      await fetchApp(mkkAppId).then((resp) => {
        if (!resp.content) {
          setCallBackMessage(resp.error)
        } else {
          setApplication(resp.content);
        }
      });
    }

    initialize(mkkAppId).then(() => {});
  }, [mkkAppId]);

  if (!application || finalStage) {
    return <EmptyContent header={"Обработка заявки"} message={callBackMessage}/>;
  }

  return (
    <div>
      {stage==="ClientDataVerification" && <ClientDataVerification
          application={application}
          onCallBack={() => setStage("FillingApplication")}/>}
      {stage==="FillingApplication" && <FillingApplication
          application={application}
          onCallBack={() => setStage("PhotosASP")}/>}
      {stage==="PhotosASP" && <PhotosASP
          application={application}
          onCallBack={() => setStage("ApplicationAgreements")}/>}
      {stage==="ApplicationAgreements" && <ApplicationAgreements
          applicationId={application.id}
          mobilePhone={application.client.contacts.mobilePhone1}
          onCallBack={async (appChanged) => {
            setStage("PreCalculation");
            if (appChanged) {
              await fetchApp(mkkAppId).then((resp) => {
                if (!resp.content) {
                  setCallBackMessage(resp.error)
                } else {
                  setApplication(resp.content);
                }
              });
            }
          }}/>}
      {stage==="PreCalculation" && <PreCalculation
          applicationId={application.id}
          limit={application.limit}
          onCallBack={(cc) => {
            setCreditContract(cc);
            setStage("LoanIssue");
          }}/>}
      {stage==="LoanIssue" && <LoanIssue
          applicationId={application.id}
          mobilePhone={application.client.contacts.mobilePhone1}
          creditContract={creditContract}
          onCallBack={() => {
            setCallBackMessage("Оформление займа завершено. Ожидайте зачисления денежных средств на счёт.")
            setFinalStage(true);
          }}/>}
    </div>
  );
}
