import Keycloak from "keycloak-js";
import {kcURL, kcRealm, kcClientId, kcOnLoad} from "../api/Environment.js";

const _kc = Keycloak({
    url: `${kcURL}`,
    realm: `${kcRealm}`,
    clientId: `${kcClientId}`
});

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback) => {
    _kc.init({
        onLoad: `${kcOnLoad}`,
        // для check sso в hidden iframe
        // onLoad: 'check-sso',
        // silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
        // checkLoginIframe: true,
        messageReceiveTimeout: 120000,
        pkceMethod: 'S256'
    })
        .then((authenticated) => {
            if (!authenticated) {
                console.log("user is not authenticated..!");
            }
            if (authenticated) {
                console.log("user authenticated!")
            }
            onAuthenticatedCallback();
        })
        .catch(console.error);
};

const doLogin = _kc.login;

const doLogout = _kc.logout;

const getToken = () => _kc.token;

const getParsedToken = () => _kc.tokenParsed;

const isLoggedIn = () => !!_kc.token;

const updateToken = (successCallback) =>
    _kc.updateToken(120)
        .then(successCallback)
        .catch(doLogin);

const getUsername = () => _kc.tokenParsed?.preferred_username;

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));

const UserService = {
    initKeycloak,
    doLogin,
    doLogout,
    isLoggedIn,
    getToken,
    getParsedToken,
    updateToken,
    getUsername,
    hasRole,
};

window.UserService = UserService

export default UserService;