import axios from "axios";
import UserService from "./UserService";

export const clientCC = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`
});

const configure = () => {
    clientCC.interceptors.request.use((config) => {
        if (UserService.isLoggedIn()) {
            const cb = () => {
                config.headers.Authorization = `Bearer ${UserService.getToken()}`;
                return Promise.resolve(config);
            };
            return UserService.updateToken(cb);
        }
    });
};

const HttpService = {
    configure
};

export default HttpService;