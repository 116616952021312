export default function Header({ title }) {
    return (
        <div style={header}>
            {title}
        </div>
    )
}

const header = {
    backgroundColor: 'rgb(16, 49, 100)',
    color: 'rgb(255, 255, 255)',
    margin: '16px 0px',
    height: '16px',
    padding: '8px',
    fontWeight: 500,
}
