import {useField} from 'formik';

export const TextInput = ({ label, ...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input>. We can use field meta to show an error
    // message if the field is invalid and it has been touched (i.e. visited)
    const [field, meta] = useField(props);
    return (
        <>
            <label htmlFor={props.id || props.name}>{label}</label>
            {props.disabled
                ? <input className={"text-input"} {...field} {...props} />
                : <input className={meta.touched && meta.error ? "text-input error" : "text-input"} {...field} {...props} />
            }

            {/*{meta.touched && meta.error ? (*/}
            {/*    <div className="error">{meta.error}</div>*/}
            {/*) : null}*/}
        </>
    );
};

export const Checkbox = ({ children, ...props }) => {
    // React treats radios and checkbox inputs differently other input types, select, and textarea.
    // Formik does this too! When you specify `type` to useField(), it will
    // return the correct bag of props for you -- a `checked` prop will be included
    // in `field` alongside `name`, `value`, `onChange`, and `onBlur`
    const [field] = useField({ ...props, type: 'checkbox' });
    return (
        <>
            <label className="checkbox-input">
                {children}
            </label>
            <input type="checkbox" {...field} {...props} />
            {/*{meta.touched && meta.error ? (*/}
            {/*    <div className="error">{meta.error}</div>*/}
            {/*) : null}*/}
        </>
    );
};

export const Select = ({ label, ...props }) => {
    const [field, meta] = useField(props);

    return (
        <>
            <label htmlFor={props.id || props.name}>{label}</label>
            <select {...field} {...props}  className={meta.touched && meta.error ? "error select" : "select"}/>
            {/*{meta.touched && meta.error ? (*/}
            {/*    <div className="error">{meta.error}</div>*/}
            {/*) : null}*/}
        </>
    );
}

export const Text = ({ label, value, ...props }) => {
    return (
        <>
            <label htmlFor={props.id || props.name}>{label}</label>
            <b className={'margin'}>{value}</b>
        </>
    );
};
