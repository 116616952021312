import React, {useState} from "react";
import {Form, Formik} from "formik";

import {
    fetchAspAgreement,
    uploadPhotosAsp,
} from '../api'
import {Text} from "../components/UI/FormItems";
import Header from "../components/UI/Header";
import LoanDetails from "../components/LoanDetails";
import {ReactComponent as Upload} from "../asssets/icons/upload.svg";
import Rejection from "./Rejection";
import "./styles.css";

const PhotosASP = ({application, onCallBack}) => {

    const [files, setFiles] = useState([]);
    const [filesError, setFilesError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [asp, setAsp] = useState(false);
    const [showRejection, setShowRejection] = useState(false);

    const uploadFile = async (e) => {
        const temp = files.filter((i) => i?.id !== e.target.id);

        setFiles([
            ...temp,
            { id: e.target.id, url: URL.createObjectURL(e.target.files[0]), name: e.target.files[0].name  },
        ]);

        setFilesError(false);
    };

    const handleASPGenerate = async () => {
        setAsp(true);
        await fetchAspAgreement(application.id);
    }

    const renderPreviewIcon = (name) => {
        const file = files.find((i) => i.id === name);

        if (!file) return null;

        const extensions = [".jpg", ".png", ".jpeg"];

        const filterExt  = extensions.filter((ext) => file.name.includes(ext));

        if (filterExt.length) {
            return <img className="preview" src={file?.url} alt="" />;
        }

        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="preview"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 20H5a2 2 0 01-2-2V6a2 2 0 012-2h10a2 2 0 012 2v1m2 13a2 2 0 01-2-2V7m2 13a2 2 0 002-2V9a2 2 0 00-2-2h-2m-4-3H9M7 16h6M7 8h6v4H7V8z"
                />
            </svg>
        );
    };

    return (
        <div>
            <Header title="Обработка заявки" />
            <Formik
                initialValues={{
                }}
                onSubmit={async () => {
                    const activeElement = document.activeElement.attributes[2].value;

                    if (activeElement === "update") {
                        if (files.length < 4) {
                            return setFilesError(true);
                        }

                        setLoading(true);

                        let fileFormData = new FormData();

                        files.forEach((file) => {
                            const inputFile = document.getElementById(file.id);
                            fileFormData.append(file.id, inputFile.files[0]);
                        });

                        const patchDocument = await uploadPhotosAsp(
                            application.id,
                            fileFormData
                        );

                        if (!patchDocument) {
                            setLoading(false);
                            return;
                        }

                        setLoading(false);
                        onCallBack()
                    }
                }}
            >
                {() => (
                    <Form>

                        <LoanDetails application={application} />

                        <fieldset>
                            <legend align="left">{"Документы по заемщику"}</legend>

                            <div className="grid-container">
                                <label htmlFor="photoPage">
                                    Паспорт - страница с фото
                                </label>
                                <div>
                                    <input
                                        type="file"
                                        id="photoPage"
                                        onChange={uploadFile}
                                        style={{ display: "none" }}
                                    />
                                    <button
                                        className="btn"
                                        type="button"
                                        onClick={() =>
                                            document.getElementById("photoPage").click()
                                        }
                                    >
                                        Загрузить <Upload className="icon" />
                                    </button>

                                    {renderPreviewIcon("photoPage")}

                                </div>

                                <label htmlFor="addressPage">
                                    Паспорт - страница с адресом регистрация
                                </label>
                                <div>
                                    <input
                                        type="file"
                                        id="addressPage"
                                        onChange={uploadFile}
                                        style={{ display: "none" }}
                                    />
                                    <button
                                        className="btn"
                                        type="button"
                                        onClick={() =>
                                            document.getElementById("addressPage").click()
                                        }
                                    >
                                        Загрузить <Upload className="icon" />
                                    </button>

                                    {renderPreviewIcon("addressPage")}

                                </div>

                                <label htmlFor="clientWithPassport">Фото клиента с паспортом</label>
                                <div>
                                    <input
                                        type="file"
                                        id="clientWithPassport"
                                        onChange={uploadFile}
                                        style={{ display: "none" }}
                                    />
                                    <button
                                        className="btn"
                                        type="button"
                                        onClick={() => document.getElementById("clientWithPassport").click()}
                                    >
                                        Загрузить <Upload className={"icon"} />
                                    </button>

                                    {renderPreviewIcon("clientWithPassport")}

                                </div>

                                <label>АСП</label>
                                <div>
                                    {!asp ? (
                                        <button
                                            className="btn"
                                            type="button"
                                            onClick={handleASPGenerate}
                                        >
                                            Сгенерировать
                                        </button>
                                    ) : (
                                        <>
                                            <input
                                                type="file"
                                                id="agreementAsp"
                                                onChange={uploadFile}
                                                style={{ display: "none" }}
                                            />
                                            <button
                                                className="btn"
                                                type="button"
                                                onClick={() =>
                                                    document.getElementById("agreementAsp").click()
                                                }
                                            >
                                                Загрузить подписанный АСП <Upload className={"icon"} />
                                            </button>

                                            {renderPreviewIcon("agreementAsp")}

                                        </>
                                    )}
                                </div>
                                <div />
                                <div />
                                {filesError && (
                                    <div>
                                        <label className="lb-error">
                                            Не все документы прикреплены
                                        </label>
                                    </div>
                                )}
                            </div>
                        </fieldset>

                        <fieldset>
                            <legend align="left">Решение</legend>
                            <div>
                                <Text label="Текущий статус" value={application.status} />
                                {!loading && (
                                    <div>

                                        <button
                                            className="btn"
                                            type="text"
                                            onClick={() => setShowRejection(true)}
                                            value="decline"
                                            style={{ marginLeft: "8px",  width: "10%" }}
                                        >
                                            Отказ
                                        </button>

                                        <button
                                            className="btn"
                                            type="submit"
                                            value="update"
                                            style={{ marginLeft: "8px",  width: "25%" }}
                                        >
                                            Предварительный расчет
                                        </button>
                                    </div>
                                )}
                            </div>
                        </fieldset>
                    </Form>
                )}
            </Formik>

            {showRejection && <Rejection
                applicationId={application.id}
                onCallBack={() => setShowRejection(false)}/>}
        </div>
    );
}

export default PhotosASP;