import React, {useState} from "react";
import {Form, Formik} from "formik";

import Header from "../components/UI/Header";
import {checkSmsCode, sendSmsCode} from "../api";
import {TextInput} from "../components/UI/FormItems";
import Rejection from "./Rejection";


const SmsVerification = ({ typeId, appId, onCodeCallback, phone }) => {

  const [isSent, setIsSent] = useState(false);
  const [errorSent, setErrorSent] = useState(false);
  const [errorCheckCode, setErrorCheckCode] = useState(false);
  const [showRejection, setShowRejection] = useState(false);
  const [checkCodeProcessing, setCheckCodeProcessing] = useState(false);
  const [sendIsAllowed, setSendIsAllowed] = useState(true);

  const handlerSentSms = async () => {

    // Disable the button
    setSendIsAllowed(false);

    setErrorSent(false);
    setIsSent(false);
    setErrorCheckCode(false);

    const resultSms = await sendSmsCode(appId, typeId)

    if(resultSms && resultSms.delivered) {

      setIsSent(true);

    }  else {

      setErrorSent(true);
      setIsSent(false);
    }

    // Simulate a 1-minute delay
    await new Promise(resolve => setTimeout(resolve, 60000));

    // Enable the button after the delay
    setSendIsAllowed(true);
  };

  const handleSubmit = async (values) => {

    setErrorCheckCode(false);
    setCheckCodeProcessing(true);

    const response = await checkSmsCode(appId, typeId, values.code);

    setCheckCodeProcessing(false);

    if (response && onCodeCallback) {
      onCodeCallback();
    } else {
      setErrorCheckCode(true);
    }
  };

  return (
    <div className="sms-verification">
      <Header title={"Ввод кода подтверждения"} />
      <fieldset>
        <legend align="left">{phone}</legend>
        <Formik
          initialValues={{code: ""}}
          onSubmit={async (values) => {isSent && !checkCodeProcessing && await handleSubmit(values)}}>
          {() => (
            <Form>
              <div>
                <TextInput
                    label="Код"
                  name="code"
                  style={{ marginRight: "8px" }}
                  placeholder=""
                />
                <button
                    className={isSent && !checkCodeProcessing ? "btn" : "not-active-btn"}
                    type="submit">
                  Проверить код
                </button>
              </div>
            </Form>
          )}
        </Formik>

        {isSent && <p className="lb-success">СМС сообщение успешно отправлено</p>}
        {errorSent && <p className="lb-error">Произошла ошибка при отправке СМС. Обратитесь к Администратору</p>}
        {errorCheckCode && <p className="lb-error">Произошла ошибка при при проверке кода</p>}

        <div style={{display: "flex", marginTop: "24px"}}>
          <button
              className="btn"
              type="text"
              onClick={() => setShowRejection(true)}
              value="decline">
            Отказ
          </button>

          <button
              className={sendIsAllowed ? "btn" : "not-active-btn"}
              onClick={handlerSentSms}
              type="submit"
              style={{ marginLeft: "10px" }}
              disabled={!sendIsAllowed}
          >
            Отправить СМС
          </button>
        </div>

        {showRejection && <Rejection
            applicationId={appId}
            onCallBack={() => setShowRejection(false)}/>}

      </fieldset>
    </div>
  );
};

export default SmsVerification;
