import {baseURL, forceDownload} from "./Environment.js";
import {clientCC} from "../services/HttpService";

/////////////////////////////////////////////////////////////////
//ОБЩЕЕ

//Получение списка клиентов по параметрам запроса


export const fetchClients  = (clientData) => {
    return clientCC.post(`${baseURL}/admin/fetch-clients`, {
        lastName: clientData.lastname,
        firstName: clientData.firstname,
        middleName: clientData.middlename,
        series: clientData.series,
        number: clientData.number
    }).then((res) => {return res.data.content});
};

//Получение списка сделок по id клиента
export const fetchDeals  = (clientId) => {
    return clientCC.get(`${baseURL}/admin/fetch-credit-contracts/${clientId}`)
        .then((res) => {return res.data.content});
};

/////////////////////////////////////////////////////////////////

/////////////////////////////////////////////////////////////////
//ВЫДАЧА
//Реанимация заявки
export const fetchReviveApplication = async (applicationId) => {
    try {
        const response = await clientCC.post(`${baseURL}/admin/application/${applicationId}/revive`);
        if (response.status === 200) {
            alert("Успешно реанимировали заявку " + applicationId);
            return true;
        }
    } catch (e) {
        alert("ОШИБКА! " + e.response.data);
        return false;
    }
};

//Запись в заявку точки продаж
export const updateApplicationPointOfSaleAdm = async(creditContractId, pointOfSaleId) => {
    try {
        await clientCC.post(`${baseURL}/admin/point-of-sale?creditContractId=${creditContractId}&pointOfSaleId=${pointOfSaleId}`, {});
        return true;
    } catch (e) {
        return false;
    }
};

//Скачивание пакета документов сделки
export const fetchDealDocumentsByContractId = async (id) => {
    try {
        const response = await clientCC.post(`${baseURL}/admin/deal-documents?creditContractId=${id}`, {}, {responseType: 'blob'});
        forceDownload([response.data], 'deal_documents.zip');
        return response.status === 200;
    } catch (e) {
        alert("Ошибка при получении пакета документов сделки")
        return false;
    }
};

//Установить сделку в статус "Выдан" и отправить файлы в 1с
export const fetchSetDealIssued = async (creditContractId) => {
    try {
        const response = await clientCC.post(`${baseURL}/admin/deal-issued/${creditContractId}`);
        if (response.status === 200) {
            alert("Успешно установили сделку в статус Выдан");
        }
    } catch (e) {
        alert("ОШИБКА! " + e.response.data);
    }
}

//Отправка запроса в ИБСО на снятие авторизации
export const fetchRevokeAccount = async (creditContractId) => {
    try {
        const response = await clientCC.post(`${baseURL}/admin/revoke-account/${creditContractId}`);
        if (response.status === 200) {
            alert("Успешно отправили запрос на снятие авторизации");
        }
    } catch (e) {
        alert("ОШИБКА! " + e.response.data);
    }
};

//Отказ в CRIF
export const fetchRejectCrifApplication = async (applicationId) => {
    try {
        const response = await clientCC.post(`${baseURL}/admin/application/${applicationId}/crif-reject`);
        if (response.status === 200) {
            alert("Успешно отправили отказ в CRIF по заявке " + applicationId);
            return true;
        }
    } catch (e) {
        alert("ОШИБКА! " + e.response.data);
        return false;
    }
};
/////////////////////////////////////////////////////////////////
//ПОГАШЕНИЯ
//Получение даты запуска последнего погашения
export const fetchLastAllRepaymentDate = () => {
    return clientCC.get(`${baseURL}/admin/last-all-repayment-date`).then((res) => {
        return res.data;
    });
};

//Запуск погашения всех сделок
export const fetchPayOffDebts = async () => {
    try {
        await clientCC.post(`${baseURL}/admin/direct-debits`);
        return false;
    } catch (e) {
        return e.response.data;
    }
};

//Отправка запроса в ИБСО на погашение
export const fetchPayment = async (creditContractId, sum, date) => {
    try {
        const response = await clientCC.post(`${baseURL}/admin/create-payment`, { creditContractId: creditContractId, sum: sum, date:date });
        if (response.status === 200) {
            alert("Успешно отправили запрос на погашение");
        }
    } catch (e) {
        alert("ОШИБКА! " + e.response.data);
    }
};

//Создание погашения на р/с МКК
export const fetchDirectReceipt = async (creditContractId, sum, date, account) => {
    try {
        const response = await clientCC.post(`${baseURL}/admin/create-direct-receipt`, {
            creditContractId: creditContractId,
            sum: sum,
            date: date,
            account: account });
        if (response.status === 200) {
            alert("Успешно добавили погашение на р/с");
        }
    } catch (e) {
        alert("ОШИБКА! " + e.response.data);
    }
}

//Загрузка файла с погашениями
export const fetchUploadManualPayments = async (formData) => {
    try {
        return await clientCC.post(`${baseURL}/admin/upload-payments-file`,
            formData,
            { headers: { "Content-Type": "multipart/form-data" }});
    } catch (e) {
        alert("ОШИБКА! " + e.response.data);
        return false;
    }
};


/////////////////////////////////////////////////////////////////
//РЕДАКТИРОВАНИЕ ДАННЫХ КЛИЕНТА
//Получение данных клиента по id
export const fetchClientData  = async(clientId) => {
    return clientCC.get(`${baseURL}/admin/fetch-client-data/${clientId}`);
};

//Обновление данных клиента
export const fetchUpdateClientData = async (id, firstName, lastName, middleName, birthDate, passportSeries,
                                            passportNumber, passportIssueDate, passportIssueAuthority,
                                            passportIssueLocation, mobilePhone, email) => {
    try {
        const response = await clientCC.post(`${baseURL}/admin/update-client-data`, {  id: id,
            firstName: firstName,
            lastName: lastName,
            middleName: middleName,
            birthDate: birthDate,
            passportSeries: passportSeries,
            passportNumber: passportNumber,
            passportIssueDate: passportIssueDate,
            passportIssueAuthority: passportIssueAuthority,
            passportIssueLocation: passportIssueLocation,
            mobilePhone: mobilePhone,
            email: email  });
        if (response.status === 200) {
            alert("Успешно обновили данные клиента");
            return true;
        }
    } catch (e) {
        alert("ОШИБКА! " + e.response);
        return false;
    }
};

/////////////////////////////////////////////////////////////////
//Расчет ПДН
//Загрузка файла со среднедушевым доходом
export const fetchUploadAverageIncome = async (formData) => {
    try {
        return await clientCC.post(`${baseURL}/admin/csv/upload-average-income`,
            formData,
            { headers: { "Content-Type": "multipart/form-data" }});
    } catch (e) {
        alert("ОШИБКА! " + e.response.data);
        return false;
    }
};

//Загрузка файла со среднерыночным значением пск
export const fetchUploadFullCost = async (formData) => {
    try {
        return await clientCC.post(`${baseURL}/admin/csv/upload-full-cost`,
            formData,
            { headers: { "Content-Type": "multipart/form-data" }});
    } catch (e) {
        alert("ОШИБКА! " + e.response.data);
        return false;
    }
};

//Загрузка файла со точками продаж
export const fetchUploadPointOfSale = async (formData) => {
    try {
        return await clientCC.post(`${baseURL}/admin/csv/upload-points-of-sale`,
            formData,
            { headers: { "Content-Type": "multipart/form-data" }});
    } catch (e) {
        alert("ОШИБКА! " + e.response.data);
        return false;
    }
};

//Загрузка файла с меппингом пск
export const fetchUploadPskMapping = async (formData) => {
    try {
        return await clientCC.post(`${baseURL}/admin/csv/upload-psk-mapping`,
            formData,
            { headers: { "Content-Type": "multipart/form-data" }});
    } catch (e) {
        alert("ОШИБКА! " + e.response.data);
        return false;
    }
};

/////////////////////////////////////////////////////////////////
//ДОБАВЛЕНИЕ ПОЛЬЗОВАТЕЛЕЙ
//Создание нового пользователя с ролью Менеджер
export const fetchCreateNewUserManager = async (login, password, comment) => {
    try {
        const response = await clientCC.post(`${baseURL}/admin/create-user-role-manager`,
            { login: login, password: password, comment: comment });
        if (response.status === 200) {
            alert("Успешно добавили пользователя " + login);
            return true;
        }
    } catch (e) {
        alert("ОШИБКА! " + e.response.data);
        return false;
    }
};

/////////////////////////////////////////////////////////////////
//ПРОЧЕЕ
//Скачивание архива реестров ибсо
export const fetchDealRegistryPack = async (id) => {
    try {
        const response = await clientCC.post(`${baseURL}/admin/deal-registry-pack?creditContractId=${id}`, {}, {responseType: 'blob'});
        forceDownload([response.data], 'deal_'+id+'_registry_pack.zip');
        return response.status === 200;
    } catch (e) {
        alert("Ошибка при получении пакета реестров")
        return false;
    }
};

//Получение текущего статуса проверки МПЛ 80
export const fetchCurrentMpl80State = async () => {
    try {
        return clientCC.get(`${baseURL}/admin/dynamic-properties/fetch-current-mpl-80-state`).then((res) => {
            return res.data;
        });
    } catch (e) {
        alert("ОШИБКА! " + e.response.data);
        return false;
    }
};

//Изменение текущего статуса проверки МПЛ 80
export const changeCurrentMpl80State = async () => {
    try {
        return clientCC.post(`${baseURL}/admin/dynamic-properties/change-current-mpl-80-state`).then((res) => {
            return res.data;
        });
    } catch (e) {
        alert("ОШИБКА! " + e.response.data);
        return false;
    }
};

//Получение текущего статуса проверки МПЛ 50-80
export const fetchCurrentMpl5080State = async () => {
    try {
        return clientCC.get(`${baseURL}/admin/dynamic-properties/fetch-current-mpl-50-80-state`).then((res) => {
            return res.data;
        });
    } catch (e) {
        alert("ОШИБКА! " + e.response.data);
        return false;
    }
};

//Изменение текущего статуса проверки МПЛ 50-80
export const changeCurrentMpl5080State = async () => {
    try {
        return clientCC.post(`${baseURL}/admin/dynamic-properties/change-current-mpl-50-80-state`).then((res) => {
            return res.data;
        });
    } catch (e) {
        alert("ОШИБКА! " + e.response.data);
        return false;
    }
};

//Получение текущего статуса автоматической отправки платежей
export const fetchCurrentPaymentState = async () => {
    try {
        return clientCC.get(`${baseURL}/admin/dynamic-properties/fetch-current-payment-state`).then((res) => {
            return res.data;
        });
    } catch (e) {
        alert("ОШИБКА! " + e.response.data);
        return false;
    }
};

//Изменение текущего статуса автоматического запроса платежей
export const changeCurrentPaymentState = async () => {
    try {
        return clientCC.post(`${baseURL}/admin/dynamic-properties/change-current-payment-state`).then((res) => {
            return res.data;
        });
    } catch (e) {
        alert("ОШИБКА! " + e.response.data);
        return false;
    }
};

//Получение текущего статуса блокировки приема заявок по ставкам
export const fetchCurrentRateFilterState = async () => {
    try {
        return clientCC.get(`${baseURL}/admin/dynamic-properties/fetch-current-rate-filter-state`).then((res) => {
            console.log(res);
            return res.data;
        });
    } catch (e) {
        alert("ОШИБКА! " + e.response.data);
        return false;
    }
};

//Изменение текущего статуса фильтра заявок
export const changeCurrentRateFilterState = async (rateFilterId) => {
    try {
        return clientCC.post(`${baseURL}/admin/dynamic-properties/change-current-rate-filter-state/${rateFilterId}`).then((res) => {
            return res.data;
        });
    } catch (e) {
        alert("ОШИБКА! " + e.response.data);
        return false;
    }
};

export const sendDocumentsToIbso = async (applicationId) => {
    try {
        const response = await clientCC.post(`${baseURL}/admin/send-documents-to-ibso/${applicationId}/`);
        alert(response.data);
    } catch (e) {
        alert("ОШИБКА! " + e.response.data);
    }
};

export const getAdminDocumentTypes = async () => {
    return clientCC.get(`${baseURL}/admin/documents/available-types/`)
        .then((res) => {return res.data.content});
};

export const generateDocumentRequest = async (applicationId, genDate, save, send, type) => {
    try {
        return await clientCC.post(`${baseURL}/admin/documents/generate`,
            {applicationId: applicationId, genDate: genDate, save: save, send: send, type: type }, {responseType: 'blob'})
            .then((response) => {
                forceDownload([response.data], type + '_generated_' + applicationId +'.pdf');
                return response.status === 200;
            }).catch(() => {
                return false;
            });
    } catch (e) {
        alert(e.message);
        return false;
    }
};

