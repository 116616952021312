export const baseURL = process.env.REACT_APP_API_URL;
export const usfStage = process.env.REACT_APP_STAGE;
export const kcURL = process.env.REACT_APP_KC_URL;
export const kcRealm = process.env.REACT_APP_KC_REALM;
export const kcClientId = process.env.REACT_APP_KC_CLIENT_ID;
export const kcOnLoad = process.env.REACT_APP_KC_ON_LOAD;

export const forceDownload = (blob, filename) => {
    const url = window.URL.createObjectURL(new Blob(blob));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    link.remove();
};