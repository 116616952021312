import React, {useEffect, useState} from "react";
import {Form, Formik} from "formik";
import * as Yup from "yup";

import Header from "../components/UI/Header";
import {Text} from "../components/UI/FormItems";
import {
    fetchCreditContract,
    checkAppActual,
    openAccountRequest
} from "../api";
import Payments from "../components/Payments";
import Rejection from "./Rejection";
import EmptyContent from "../components/EmptyContent";
import Insurance from "./Insurance";


const PreCalculation = ({applicationId, limit, onCallBack}) => {

    const [creditContract, setCreditContract] = useState(undefined);
    const [isCalculate, setIsCalculate] = useState(false)
    const [showRejection, setShowRejection] = useState(false);
    const [activeBtn, setActiveBtn] = useState(true);
    const [callBackMessage, setCallBackMessage] = useState("Ожидайте загрузки информации...");
    const [preCalcError, setPreCalcError] = useState(false);
    const [showInsuranceWindow, setShowInsuranceWindow] = useState(false);

    useEffect(() => {
        async function initialize() {
            const resp = await fetchCreditContract(applicationId);
            if (resp) {
                setCreditContract(resp);
            } else {
                setCallBackMessage("Ошибка при получении сделки. Вероятно сделка не создана, или произошла внутренняя ошибка.")
            }
        }

        initialize().then(() => {});
    }, [applicationId]);

    const handleCalculation = async () => {

        setIsCalculate(true);

        const resp = await checkAppActual(applicationId);
        if(!resp.isActual) {
            setCallBackMessage("Предложение не актуально. Дальнейшее оформление займа невозможно. По займу принято отрицательное решение.")
            setPreCalcError(true);
        } else {
            const resp = await openAccountRequest(applicationId);
            if (!resp) {
                setCallBackMessage("Произошла ошибка при запросе на открытие счёта. Дальнейшее оформление займа невозможно. Обратитесь к администратору.")
                setPreCalcError(true);
            } else {
                onCallBack(creditContract);
            }
        }
    };

    if (!creditContract || preCalcError) {
        return <EmptyContent header="Предварительный расчет" message={callBackMessage}/>;
    }

    return (
    <div>
      <Header title="Предварительный расчет" />
      <Formik
        initialValues={{
          reqSum: creditContract.sum,
          term: creditContract.creditPaymentPlan.length
        }}
        validationSchema={Yup.object({
          reqSum: Yup.number().min(15000).max(limit),
        })}
        onSubmit={async (values) => { activeBtn && await handleCalculation(values);
        }}
      >
        {({ values, errors }) => (
          <Form>
            <fieldset style={{ display: 'flex', flexDirection: 'column' }}>
              <div className="grid-container-50">

                <Text label="Сумма займа" value={values.reqSum}/>

                <Text label="Максимальный лимит" value={limit}/>
                <Text label="Срок" value={values.term}/>
                <Text label="Минимальный лимит" value="15000"/>
                <Text label="Ставка" value={creditContract.creditPercent}/>

                <Text label="Ежемесячный платеж" value={creditContract.creditPaymentPlan[1].paymentSum}/>
                <Text label="ПСК" value={creditContract.fullCreditCost}/>

                <div>

                  {!isCalculate &&
                  <button
                      className={activeBtn ? "btn" : "not-active-btn"}
                      name="calculate"
                      type="submit"
                      style={{
                        maxWidth: '150px',
                        gridColumnStart: 4,
                        gridColumnEnd: 5,
                        marginLeft: "20px",
                        width: "30%"}}>
                    Расчитать
                  </button>
                  }

                </div>
              </div>
            </fieldset>
          </Form>
        )}
      </Formik>

      {showRejection && <Rejection
          applicationId={applicationId}
          onCallBack={() => setShowRejection(false)}/>}

      {showInsuranceWindow && <Insurance
          amount = {creditContract.sum}
          term = {creditContract.creditPaymentPlan.length}
          onCallBack={() => {setShowInsuranceWindow(false);console.log(creditContract)}}/>}

      <div>
        <button
            className="btn"
            type="text"
            onClick={() => setShowRejection(true)}
            value="decline"
            style={{ marginLeft: "24px", width: "10%", align: "center" }}
        >
          Отказ
        </button>
          <button
              className="btn"
              type="text"
              onClick={() => setShowInsuranceWindow(true)}
              style={{ marginLeft: "24px", align: "center" }}
          >
              Показать окно страховки
          </button>
      </div>

      <Payments items={creditContract.creditPaymentPlan} />
    </div>
    );
};

export default PreCalculation;
