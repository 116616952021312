import React, {useCallback, useState} from 'react'
import {fetchAllApps} from "../api";
import Header from "../components/UI/Header";
import AllAppTable from '../components/AllAppTable'
import {useQuery} from '../components/helpers/useQuery'
import ApplicationProcessing from './ApplicationProcessing'

const AllApp = () => {
  const id = useQuery().get("mkk_id");

  const [currentApps, setCurrentApps] = useState([]);
  const [response, setResponse] = useState(null);

  const fetchApplication = useCallback(async ({ pageSize, pageIndex, filter }) => {
    await fetchAllApps(pageSize, pageIndex, filter)
      .then((app) => {
        let newApp = [];

        app.content.forEach((item) => {

          newApp.push({
            id: item.id,
            mkkAppId: item.mkkAppId,
            name: item.fio,
            status: item.applicationStatus,
            apprDate: item.apprDate,
            endDate: item.endDate,
            birthdate: item.birthday,
            passport: item.passport,
            limit: item.limit,
            branchId: item.branchId,
          });

        });

        setCurrentApps(() => newApp);

        setResponse(() => app);
      })
      .finally(() => {});
  }, [])

  if(id) return <ApplicationProcessing />

  return (
    <div>
      <Header title="Заявки" />
      <AllAppTable
          apps={currentApps}
          fetchData={fetchApplication}
          pageCount={response?.pageInfo?.totalPages}
          currentPage={response?.pageInfo?.number}
      />
    </div>
  );
};

export default AllApp;
