import React from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import "./styles.css";
import ApplicationProcessing from "../views/ApplicationProcessing";
import AllApp from '../views/AllApp'
import AdminOffice from "../views/AdminOffice";

export default function NavBar() {
    return (
        <Router>
            <Switch>

                <Route path="/application/:id" component={ApplicationProcessing} />
                <Route path="/application" component={ApplicationProcessing} />

                <Route path="/admin" component={AdminOffice} />

                <Route path="/" component={AllApp} />

            </Switch>
        </Router>
    );
}
