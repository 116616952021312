import React, {useEffect, useState} from "react";
import {Form, Formik} from "formik";
import * as Yup from "yup";

import {
    checkAppActual,
    patchApplication,
    fetchCountries,
    fetchRegions
} from '../api'
import {Checkbox, Text, TextInput} from "../components/UI/FormItems";
import Header from "../components/UI/Header";
import EmptyContent from "../components/EmptyContent";
import LoanDetails from "../components/LoanDetails";
import RegionContainer from "../containers/Region";
import SexContainer from "../containers/Sex";
import MaritalStatusContainer from "../containers/MaritalStatus";
import Rejection from "./Rejection";
import "./styles.css";

const FillingApplication = ({application, onCallBack}) => {

    const [regions, setRegions] = useState([]);
    const [countries, setCountries] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showRejection, setShowRejection] = useState(false);
    const [showApp, setShowApp] = useState(false);
    const [callBackMessage, setCallBackMessage] = useState("Ожидайте загрузки информации...");

    useEffect(() => {
        async function initialize() {
            await fetchCountryState();
            await fetchRegionsState();
            await fetchRegionsState();
            await fetchActualStatus(application.id);
        }

        async function fetchRegionsState() {
            await fetchRegions().then((data) => setRegions(data));
        }

        async function fetchCountryState() {
            await fetchCountries().then((data) => setCountries(data));
        }

        async function fetchActualStatus(applicationId) {
            const resp = await checkAppActual(applicationId);
            if(!resp.isActual) {
                setCallBackMessage("Заявка не актуальна при проверке в ЕБПП. Дальнейшее оформление займа невозможно. По займу принято отрицательное решение.")
            } else {
                setShowApp(true);
            }
        }

        initialize().then(() => {});
    }, [application]);

    const addressSchema = Yup.object({
        countryId: Yup.number().moreThan(0).required(),
        regionId: Yup.number().moreThan(0).required(),
        house: Yup.string().required(),
        cityName: Yup.string().required(),
        apartment: Yup.string().required(),
        streetName: Yup.string().required(),
        fullName: Yup.string().required()
    });

    const clientSchema = Yup.object({
        client: Yup.object({
            addressMatches: Yup.boolean(),
            sexId: Yup.number().min(1).required(),
            maritalStatusId: Yup.number().min(1).required(),
            inn: Yup.number(),
            email: Yup.string().email("Invalid email address"),
            monthlyCommitment: Yup.number(),
            birthCountryId: Yup.number().moreThan(0).required(),
            birthplace: Yup.string().required(),
            income: Yup.number().moreThan(0).required(),
            regAddress: addressSchema,
            actAddress: Yup.object().when('addressMatches',{
                is: false,
                then: addressSchema
            })
        })
    });

    const russia = countries.filter((region) => region.oksmCode === "643")[0]?.id.toString();

    if (!showApp) {
        return <EmptyContent header={"Обработка заявки"} message={callBackMessage}/>;
    }

    return (
        <div>
            <Header title="Обработка заявки" />
            <Formik
                initialValues={{
                    mobilePhone1: application.client.contacts.mobilePhone1 || "",
                    term: application.term || "",
                    issueDate: application.client.document.issueDate || "",
                    issueAuthority: application.client.document.issueAuthority || "",
                    issueLocation: application.client.document.issueLocation || "",
                    client: {
                        addressMatches:
                            application.client.addresses.actAddress.fullName ===
                            application.client.addresses.regAddress.fullName,
                        birthDate: application.client.birthdate,
                        birthplace: application.client.birthplace || "",
                        birthCountryId: application.client.birthCountryId || russia,
                        childrenCount: application.client.clientMisc.childrenCount || "",
                        email: application.client.contacts.email || "",
                        income: application.client.clientMisc.income || "",
                        inn: application.client.inn || "",
                        maidenName: application.client.maidenName || "",
                        maritalStatusId:
                            application.client.clientMisc.maritalStatus?.id || 0,
                        monthlyCommitment:
                            application.client.clientMisc.monthlyCommitment || "",
                        residenceCountryId:
                            application.client.residenceCountryId || russia,
                        citizenshipCountryId:
                            application.client.citizenshipCountryId || russia,
                        sexId: application.client.sex?.id || 0,
                        actAddress: {
                            index: application.client.addresses.actAddress.index || null,
                            regionId: application.client.addresses.actAddress.regionId || 0,
                            countryId:
                                application.client.addresses.actAddress.countryId ||
                                russia,
                            areaName: application.client.addresses.actAddress.areaName || null,
                            placeName:
                                application.client.addresses.actAddress.placeName || null,
                            cityName: application.client.addresses.actAddress.cityName || null,
                            districtName:
                                application.client.addresses.actAddress.districtName || null,
                            streetName:
                                application.client.addresses.actAddress.streetName || null,
                            house: application.client.addresses.actAddress.house || null,
                            corpus: application.client.addresses.actAddress.corpus || null,
                            building: application.client.addresses.actAddress.building || null,
                            letter: application.client.addresses.actAddress.letter || null,
                            apartment:
                                application.client.addresses.actAddress.apartment || null,
                            fullName: application.client.addresses.actAddress.fullName || null,
                        },
                        regAddress: {
                            index: application.client.addresses.regAddress.index || null,
                            regionId: application.client.addresses.regAddress.regionId || 0,
                            countryId:
                                application.client.addresses.regAddress.countryId ||
                                russia,
                            areaName: application.client.addresses.regAddress.areaName || null,
                            placeName:
                                application.client.addresses.regAddress.placeName || null,
                            cityName: application.client.addresses.regAddress.cityName || null,
                            districtName:
                                application.client.addresses.regAddress.districtName || null,
                            streetName:
                                application.client.addresses.regAddress.streetName || null,
                            house: application.client.addresses.regAddress.house || null,
                            corpus: application.client.addresses.regAddress.corpus || null,
                            building: application.client.addresses.regAddress.building || null,
                            letter: application.client.addresses.regAddress.letter || null,
                            apartment:
                                application.client.addresses.regAddress.apartment || null,
                            fullName: application.client.addresses.regAddress.fullName || null,
                        },
                        document: {
                            issueAuthority: application.client.document.issueAuthority || null,
                            issueLocation: application.client.document.issueLocation || null
                        }
                    }
                }}
                validationSchema={clientSchema}
                validateOnMount
                initialTouched={{
                    client: {
                        sexId: true,
                        maritalStatusId: true,
                        email: true,
                        birthCountryId: true,
                        birthplace: true,
                        regAddress: {
                            countryId: true,
                            regionId: true,
                            house: true,
                            cityName: true,
                            apartment: true,
                            streetName: true,
                            fullName: true,
                            income: true,
                        }
                    }
                }}
                onSubmit={async (values) => {
                    const activeElement = document.activeElement.attributes[2].value;

                    if (activeElement === "update") {

                        setLoading(true);

                        const sentData = { client: { ...values.client } };

                        if (values.client.addressMatches) sentData.client.actAddress = null;

                        const patchResult = await patchApplication(application.id, sentData);

                        if (!patchResult) {
                            console.log("Не прошли")
                            console.log(patchResult)
                            setLoading(false);
                            return;
                        }

                        const updApp = {patchResult};
                        setLoading(false);
                        onCallBack(updApp)
                    }
                }}
            >
                {({ values }) => (
                    <Form>
                        <fieldset>
                            <legend align="left">{"Заемщик"}</legend>
                            <div className="grid-container">
                                <Text
                                    label={"ФИО"}
                                    value={
                                        application.client.lastName +
                                        " " +
                                        application.client.firstName +
                                        " " +
                                        application.client?.middleName
                                    }
                                />

                                <div />
                                <div />

                                <SexContainer label="Пол" name="client.sexId" />

                                <Text
                                    label={"Паспорт (серия номер)"}
                                    value={
                                        application.client.document.series +
                                        " " +
                                        application.client.document.number
                                    }
                                />

                                <TextInput
                                    label="Место рождения (город)"
                                    name="client.birthplace"
                                    placeholder="Место рождения"
                                />

                                <Text label={"Дата выдачи"} value={values.issueDate} />

                                <TextInput
                                    label="Девичья фамилия"
                                    name="client.maidenName"
                                    placeholder="Девичья фамилия"
                                />

                                <Text label={"Дата рождения"} value={values.client.birthDate} />

                                <RegionContainer
                                    label="Страна рождения"
                                    name="client.birthCountryId"
                                    placeholder="Место рождения"
                                    regions={countries}
                                    keyname="shortName"
                                    canNotSelect
                                />

                                <TextInput
                                    label={"Кем выдан"}
                                    name={"client.document.issueAuthority"}
                                    type={"Кем выдан"}
                                    placeholder={"E-mail"}
                                />

                                <RegionContainer
                                    label="Страна гражданства"
                                    name="client.citizenshipCountryId"
                                    placeholder="Страна гражданства"
                                    regions={countries}
                                    keyname="shortName"
                                    oksmCode="643"
                                    disabled
                                />

                                <TextInput
                                    label={"Код подразделения"}
                                    name={"client.document.issueLocation"}
                                    type={"Код подразделения"}
                                    placeholder={"Код подразделения"}
                                />

                                <RegionContainer
                                    label="Страна резиденства"
                                    name="client.residenceCountryId"
                                    placeholder="Страна резиденства"
                                    regions={countries}
                                    keyname="shortName"
                                    oksmCode="643"
                                    disabled
                                />

                                <TextInput
                                    label={"E-mail"}
                                    name={"client.email"}
                                    type={"E-mail"}
                                    placeholder={"E-mail"}
                                />

                                <TextInput
                                    label="Количество детей"
                                    name="client.childrenCount"
                                    placeholder="Количество детей"
                                    type="number"
                                />

                                <Text label={"Телефон"} value={values.mobilePhone1} />

                                <MaritalStatusContainer
                                    label="Семейное положение"
                                    name="client.maritalStatusId"
                                />

                                <TextInput
                                    label="ИНН"
                                    name="client.inn"
                                    placeholder="ИНН"
                                    type="number"
                                />

                                <TextInput
                                    label="Текущие ежемесячные обязательства"
                                    name="client.monthlyCommitment"
                                    placeholder="Текущие ежемесячные обязательства"
                                    value={values.client.monthlyCommitment}
                                />

                                <TextInput
                                    label="Доход ежемесячный"
                                    name="client.income"
                                    type="number"
                                    placeholder="Доход"
                                />

                                <TextInput
                                    label="Адрес регистрации"
                                    name="client.regAddress.fullName"
                                    placeholder="Адрес регистрации"
                                />

                                <TextInput
                                    label="Адрес проживания"
                                    name="client.actAddress.fullName"
                                    placeholder="Адрес проживания"
                                />

                                <div />
                                <div />

                                <Checkbox name="client.addressMatches">
                                    Совпадает с регистрацией
                                </Checkbox>

                                <div
                                    className="grid-container-3-auto"
                                    style={{ gridColumnStart: 1, gridColumnEnd: 3 }}
                                >

                                    <RegionContainer
                                        label="Страна"
                                        name="client.regAddress.countryId"
                                        placeholder="Страна"
                                        regions={countries}
                                        keyname="shortName"
                                        style={{ maxWidth: "140px" }}
                                        canNotSelect
                                    />

                                    <RegionContainer
                                        label="Регион"
                                        name="client.regAddress.regionId"
                                        placeholder="Регион"
                                        regions={regions}
                                        canNotSelect
                                    />

                                    <TextInput
                                        label="Область"
                                        name="client.regAddress.areaName"
                                        placeholder="Область"
                                    />

                                    <TextInput
                                        label="Город/Посёлок/Село"
                                        name="client.regAddress.cityName"
                                        placeholder="Город/Посёлок/Село"
                                    />

                                    <TextInput
                                        label="Район"
                                        name="client.regAddress.districtName"
                                        placeholder="Район"
                                    />

                                    <TextInput
                                        label="Улица"
                                        name="client.regAddress.streetName"
                                        placeholder="Улица"
                                    />

                                    <TextInput
                                        label="Дом"
                                        name="client.regAddress.house"
                                        placeholder="Дом"
                                    />

                                    <TextInput
                                        label="Корпус"
                                        name="client.regAddress.corpus"
                                        placeholder="Корпус"
                                    />

                                    <TextInput
                                        label="Строение"
                                        name="client.regAddress.building"
                                        placeholder="Строение"
                                    />

                                    <TextInput
                                        label="Литера"
                                        name="client.regAddress.letter"
                                        placeholder="Литера"
                                    />

                                    <TextInput
                                        label="Кв"
                                        name="client.regAddress.apartment"
                                        placeholder="Квартира"
                                    />

                                    <TextInput
                                        label="Индекс"
                                        name="client.regAddress.index"
                                        placeholder="Индекс"
                                    />

                                </div>

                                <div
                                    className="grid-container-3-auto"
                                    style={{ gridColumnStart: 3, gridColumnEnd: 5 }}
                                >

                                    <RegionContainer
                                        label="Страна"
                                        name="client.actAddress.countryId"
                                        placeholder="Страна"
                                        regions={countries}
                                        keyname="shortName"
                                        style={{ maxWidth: "140px" }}
                                        canNotSelect
                                    />

                                    <RegionContainer
                                        label="Регион"
                                        name="client.actAddress.regionId"
                                        placeholder="Регион"
                                        regions={regions}
                                        canNotSelect
                                    />

                                    <TextInput
                                        label="Область"
                                        name="client.actAddress.areaName"
                                        placeholder="Область"
                                    />

                                    <TextInput
                                        label="Город/Посёлок/Село"
                                        name="client.actAddress.cityName"
                                        placeholder="Город/Посёлок/Село"
                                    />

                                    <TextInput
                                        label="Район"
                                        name="client.actAddress.districtName"
                                        placeholder="Район"
                                    />

                                    <TextInput
                                        label="Улица"
                                        name="client.actAddress.streetName"
                                        placeholder="Улица"
                                    />

                                    <TextInput
                                        label="Дом"
                                        name="client.actAddress.house"
                                        placeholder="Дом"
                                        type="number"
                                    />

                                    <TextInput
                                        label="Корпус"
                                        name="client.actAddress.corpus"
                                        placeholder="Корпус"
                                    />

                                    <TextInput
                                        label="Строение"
                                        name="client.actAddress.building"
                                        placeholder="Строение"
                                    />

                                    <TextInput
                                        label="Литера"
                                        name="client.actAddress.letter"
                                        placeholder="Литера"
                                    />

                                    <TextInput
                                        label="Кв"
                                        name="client.actAddress.apartment"
                                        placeholder="Квартира"
                                    />

                                    <TextInput
                                        label="Индекс"
                                        name="client.actAddress.index"
                                        placeholder="Индекс"
                                        type="number"
                                    />

                                </div>
                            </div>
                        </fieldset>

                        <LoanDetails application={application} />

                        <fieldset>
                            <legend align="left">Решение</legend>
                            <div>
                                <Text label="Текущий статус" value={application.status} />
                                {!loading && (
                                    <div>

                                        <button
                                            className="btn"
                                            type="text"
                                            onClick={() => setShowRejection(true)}
                                            value="decline"
                                            style={{ marginLeft: "8px",  width: "10%" }}
                                        >
                                            Отказ
                                        </button>

                                        <button
                                            className="btn"
                                            type="submit"
                                            value="update"
                                            style={{ marginLeft: "8px",  width: "25%" }}
                                        >
                                            Перейти на этап прикрепления фото и АСП
                                        </button>
                                    </div>
                                )}
                            </div>
                        </fieldset>
                    </Form>
                )}
            </Formik>

            {showRejection && <Rejection
                applicationId={application.id}
                onCallBack={() => setShowRejection(false)}/>}
        </div>
    );
}

export default FillingApplication;