import React, {useEffect, useState} from "react";
import {
  fetchAgreements,
  okbRequest,
  isCreditContractCreated,
  maxIssueSumCheck,
  checkDealFullCost,
  checkMplValue
} from '../api'
import SmsVerification from "./SmsVerification";
import "./styles.css";
import Rejection from "./Rejection";
import EmptyContent from "../components/EmptyContent";

const ApplicationAgreements = ({applicationId, mobilePhone, onCallBack}) => {

  const [showSms, setShowSms] = useState(false);
  const [isDownload, setIsDownload] = useState(false);
  const [showRejection, setShowRejection] = useState(false);
  const [callBackMessage, setCallBackMessage] = useState(undefined);
  const [progressMessage, setProgressMessage] = useState(undefined);
  const [showProgressMessage, setShowProgressMessage] = useState(false);

  useEffect(() => {
    async function initialize() {
      await fetchAgreements(applicationId).then(() => {setIsDownload(true)});
    }

    initialize().then(() => {});
  }, [applicationId]);


  const handleMaxIssueSumCheck = async () => {

    setProgressMessage("Запрашиваем данные в ОКБ...");

    const resultOkb = await okbRequest(applicationId);

    if (!resultOkb) {
      setCallBackMessage("Ошибка при получении кредитной истории. Дальнейшее оформление займа невозможно. По займу принято отрицательное решение.");
      return;
    }

    setProgressMessage("Идет создание документов. Процесс может занять вплоть до минуты. Пожалуйста, подождите...");

    await createDeal(0);
  }


  const handleSmsCallback = async () => {

    setShowSms(false);
    setShowProgressMessage(true);
    setIsDownload(false);
    setProgressMessage("Проверка максимально допустимой суммы займа...");

    const resultCheck = await maxIssueSumCheck(applicationId);
    if (!resultCheck) {
      setCallBackMessage("Ошибка при получении данных по текущим займам клиента из 1с. Попробуйте позже.");
    } else {
      if (!resultCheck.passed) {
        setCallBackMessage("У клиента обнаружено превышение допустимой суммы выдачи займа. Заявка отклонена.");
      } else {
        if (resultCheck.limitChanged) {
          const continueProcessing = window.confirm("После проверки ПСК займ можно оформить только на сумму, не превышающую " + resultCheck.newLimit + " рублей. Продолжить?")
          if (continueProcessing) {
            await handleMaxIssueSumCheck();
          } else {
            setProgressMessage("Для продолжения оформления заявки необходимо открыть её заново");
            setShowRejection(true);
          }
        } else {
          await handleMaxIssueSumCheck();
        }
      }
    }
  };

  async function createDeal(i) {
    const result = await isCreditContractCreated(applicationId);
    if (!result) {
      i++;
      if (i < 6) {
        setTimeout(function () {
          createDeal(i);
        }, 10000);
      } else {
        setCallBackMessage("Произошла ошибка при создании документов сделки! Не удалось создать документы в течении минуты. Обратитесь к администратору.");
      }
    } else {

      //Проверка ПСК
      const fullCostCheckResult = await checkDealFullCost(applicationId);
      let appChanged = false;
      if (!fullCostCheckResult) {
        setCallBackMessage("Произошла ошибка при проверке ПСК. Обратитесь к администратору.");
        return;
      } else {
        if (fullCostCheckResult.passed) {
          if (fullCostCheckResult.limitChanged) {
            alert("После проверки ПСК займ можно оформить только на сумму, не превышающую " + fullCostCheckResult.newLimit + " рублей.")
            appChanged = true;
          }
        } else {
          setCallBackMessage(fullCostCheckResult.errorMessage);
          return;
        }
      }

      //Проверка МПЛ
      const mplCheckResult = await checkMplValue(applicationId);
      if (!mplCheckResult) {
        setCallBackMessage("Произошла ошибка при проверке ПДН. Обратитесь к администратору.");
        return;
      } else {
        if (mplCheckResult.passed) {
          if (mplCheckResult.limitChanged) {
            alert("После проверки ПДН займ можно оформить только на сумму, не превышающую " + mplCheckResult.newLimit + " рублей.")
            appChanged = true;
          }
        } else {
          setCallBackMessage(mplCheckResult.errorMessage);
          return;
        }
      }
      setShowProgressMessage(false);
      onCallBack(appChanged);
    }
  }

  if (callBackMessage) {
    return <EmptyContent header={"Создание документов сделки"} message={callBackMessage}/>;
  }

  return (
    <fieldset>
      <legend align="left">Формы по заемщику</legend>

      <div>

        <button
            className="btn"
            type="text"
            onClick={() => setShowRejection(true)}
            value="decline"
            style={{ marginLeft: "10px", width: "10%" }}
        >
          Отказ
        </button>

        {isDownload && (
          <button
            className="btn"
            type="button"
            style={{ marginLeft: "10px", width: "20%" }}
            onClick={() => setShowSms(true)}
            disabled={showProgressMessage}
          >
            Документы переданы заемщику
          </button>
        )}
        {showProgressMessage && (
            <p style={{ marginLeft: "0" }}>{progressMessage}</p>
        )}
      </div>

      {showSms && (
        <SmsVerification
          appId={applicationId}
          onCodeCallback={handleSmsCallback}
          phone={mobilePhone}
          typeId="AGREEMENT"
        />
      )}

      {showRejection && <Rejection
          applicationId={applicationId}
          onCallBack={() => setShowRejection(false)}/>}
    </fieldset>
  );
}

export default ApplicationAgreements;